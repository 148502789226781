<app-auth-container>
  <img class="autentique-logo smartphone" src="assets/logo-and-text-white.png" alt="" />
  <img class="autentique-logo tablet" src="assets/Autentique.svg" alt="" />
  <p class="auth__title">{{ 'auth.registerWith' | translate }}</p>
  <div class="auth__methods forceDefaultColors">
    <app-google-oauth
      class="auth__methods-option"
      context="signup"
      uxMode="popup"
      type="standard"
      shape="rectangular"
      theme="outline"
      text="signup_with"
      size="large"
      width="235"
      logoAlignment="left"
      (callback)="login(AuthenticationMethod.Google, $event)"
      *ngIf="!isElectron"
    >
    </app-google-oauth>
    <button type="button" class="auth__methods-option face" [disabled]="isLoading" (click)="login(AuthenticationMethod.Facebook)" *ngIf="!isElectron">
      <i class="fab fa-facebook"></i>
      <span>{{ 'auth.registerWithFacebook' | translate }}</span>
    </button>
    <button type="button" class="auth__methods-option microsoft" [disabled]="isLoading" (click)="login(AuthenticationMethod.Microsoft)" *ngIf="!isElectron">
      <figure class="icon hide" style='background-image: url("/assets/microsoft.svg")'></figure>
      <span>{{ 'auth.registerWithMicrosoft' | translate }}</span>
    </button>
    <button type="button" class="auth__methods-option wtap" [disabled]="isLoading" (click)="login(AuthenticationMethod.Sms)" *ngIf="route.snapshot.queryParams.sms">
      <figure class="icon hide" style='background-image: url("/assets/smartphone.svg")'></figure>
      <span>{{ 'auth.registerWithPhone' | translate }}</span>
    </button>
  </div>
  <p class="auth__alternative" *ngIf="!isElectron || route.snapshot.queryParams.sms">{{ 'auth.registerWithEmail' | translate }}</p>
  <form class="field__form" (ngSubmit)="register()" [formGroup]="form" novalidate>
    <p class="field__form-title">{{ 'profile.accessData' | translate }}</p>

    <label class="field__holder" [appFormControlWrap]="form.get('email')">
      <input type="email" class="field" formControlName="email" name="email" autocomplete="email" required appValidEmail />
      <span class="field__placeholder">{{ 'profile.email' | translate }}</span>
    </label>

    <label class="field__holder" [appFormControlWrap]="form.get('password')">
      <input type="password" class="field" formControlName="password" name="password" autocomplete="new-password" required minlength="8" appShowPassword data-sentry-mask />
      <span class="field__placeholder">{{ 'auth.password' | translate }}</span>
    </label>

    <app-switch-tabs
      name="type"
      [options]="[
        [false, translateService.instant('profile.naturalPerson')],
        [true, translateService.instant('profile.legalEntity')]
      ]"
      [(ngModel)]="isCompany"
      [ngModelOptions]="{ standalone: true }"
    ></app-switch-tabs>

    <p class="field__form-title" [class.d-none]="!isCompany">{{ 'profile.companyData' | translate }}</p>

    <label class="field__holder" [class.d-none]="!isCompany" [appFormControlWrap]="form.get('company')">
      <input type="text" class="field" formControlName="company" name="company" autocomplete="company" />
      <span class="field__placeholder">{{ 'profile.businessName' | translate }}</span>
    </label>

    <label class="field__holder" [class.d-none]="!isCompany" [appFormControlWrap]="form.get('cnpj')">
      <input type="tel" class="field" formControlName="cnpj" name="cnpj" autocomplete="cnpj" mask="00.000.000/0000-00" />
      <span class="field__placeholder">{{ 'profile.cnpj' | translate }}</span>
    </label>

    <p class="field__form-title">{{ 'profile.personalInfo' | translate }}</p>

    <label class="field__holder" [appFormControlWrap]="form.get('name')">
      <input type="text" class="field" formControlName="name" name="name" autocomplete="name" required appValidName appFullName appForbiddenNames />
      <span class="field__placeholder">{{ 'profile.fullName' | translate }}</span>
    </label>

    <!--
    <label class="field__holder cpf" [appFormControlWrap]="form.get('cpf')">
      <input type="tel" class="field" formControlName="cpf" name="cpf" autocomplete="cpf" mask="000.000.000-00" required />
      <span class="field__placeholder">CPF</span>
    </label>
-->

    <label class="field__holder birthday" [appFormControlWrap]="form.get('birthday')">
      <input type="tel" class="field" formControlName="birthday" name="birthday" autocomplete="birthday" mask="00/00/0000" [dropSpecialCharacters]="false" [specialCharacters]="['/']" required />
      <span class="field__placeholder">{{ 'profile.birthDate' | translate }}</span>
    </label>

    <div class="row">
      <div class="col-lg-6" [appFormControlWrap]="form.get('country')">
        <app-select placeholder="{{ 'profile.country' | translate }}" formControlName="country" [options]="countries" [required]="true"></app-select>
      </div>
      <div class="col-lg-6" [appFormControlWrap]="form.get('language')">
        <app-select
          placeholder="{{ 'profile.language' | translate }}"
          formControlName="language"
          (click)="translateService.use(form.get('language').value)"
          [options]="languages"
          [disableSearch]="true"
        ></app-select>
      </div>
    </div>

    <div class="submit-holder">
      <button type="button" routerLink="/entrar" queryParamsHandling="preserve" class="submit back b-small">{{ 'button.back' | translate }}</button>
      <button type="submit" class="submit b-small" [disabled]="isLoading">{{ 'button.forward' | translate }}</button>
    </div>
  </form>
</app-auth-container>
