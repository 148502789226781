<!-- Desktop -->
<div class="modal-body" [class.d-none]="!desktopStep">
  <ng-container [ngTemplateOutlet]="tipsTitleTemplate" *ngIf="currentStep === Steps.Intro"></ng-container>
  <ng-container [ngTemplateOutlet]="hasBackPromptTemplate" *ngIf="currentStep === Steps.HasBackPrompt"></ng-container>
  <ng-container *ngIf="[Steps.Front, Steps.Back, Steps.Selfie].includes($any(currentStep))">
    <ng-container *ngIf="currentStep === Steps.Front">
      <p class="centermodal__title">{{ 'documents.takePhotoOfDocument' | translate }}</p>
      <p class="centermodal__description">{{ 'documents.alignDocumentInImage' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="currentStep === Steps.Back">
      <p class="centermodal__title">{{ 'documents.takePhotoOfDocumentBack' | translate }}</p>
      <p class="centermodal__description">{{ 'documents.takePhotoOfDocumentBackDescription' | translate }}</p>
    </ng-container>
    <ng-container *ngIf="currentStep === Steps.Selfie">
      <p class="centermodal__title">{{ 'documents.takePhotoOfFace' | translate }}</p>
      <p class="centermodal__description">{{ 'documents.alignFaceInTheImage' | translate }}</p>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="currentStep === Steps.MobilePrompt">
    <p class="centermodal__title">{{ 'documents.smartphoneVerification' | translate }}</p>
    <p class="centermodal__description">{{ 'documents.scanQRCode' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="currentStep === Steps.CameraError">
    <p class="centermodal__title">{{ 'documents.biometricVerification' | translate }}</p>
    <p class="centermodal__description">
      {{ 'documents.cameraAccessError' | translate }}
    </p>
  </ng-container>
  <ng-container *ngIf="currentStep === Steps.VerificationError">
    <p class="centermodal__title">{{ 'documents.verificationFailure' | translate }}</p>
    <p class="centermodal__description" *ngIf="verification?.type === SecurityVerificationEnum.Manual">
      {{ 'documents.verificationFailureMessage' | translate }}
    </p>
    <p class="centermodal__description" *ngIf="verification?.type !== SecurityVerificationEnum.Manual">
      {{ 'documents.lowSimilarityErrorMessage' | translate }}
    </p>
  </ng-container>

  <div class="centermodal__form" [class.d-none]="currentStep === Steps.MobilePrompt">
    <div class="mx-5 mb-5" *ngIf="currentStep === Steps.Intro">
      <ng-container [ngTemplateOutlet]="tipsContentTemplate"></ng-container>
      <div class="centermodal__button-group centermodal__button-group--vertical mb-3">
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" (click)="loadCamera()">
          {{ 'documents.useComputerCamera' | translate }}
        </button>
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center" (click)="selectDesktopMobilePrompt()">
          {{ 'documents.useSmartphoneCamera' | translate }}
        </button>
      </div>
    </div>

    <div class="camera" [class.d-none]="![Steps.Front, Steps.Back, Steps.Selfie].includes($any(currentStep))">
      <div class="camera-focus {{ currentStep }}" *ngIf="!isLoading" [attr.data-loading]="translateService.instant('shared.loading')"></div>
      <video crossorigin="anonymous" #video></video>
    </div>

    <ng-container *ngIf="currentStep === Steps.HasBackPrompt">
      <div class="centermodal__button-group centermodal__button-group--vertical mb-3">
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" (click)="currentStep = Steps.Back">
          {{ 'documents.takeBackPhoto' | translate }}
        </button>
        <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center" (click)="useFrontAsBackPicture()">
          {{ 'documents.noBackPhoto' | translate }}
        </button>
      </div>
    </ng-container>

    <div class="centermodal__button-group centermodal__button-group--vertical mb-5" *ngIf="[Steps.CameraError, Steps.VerificationError].includes($any(currentStep))">
      <div class="text-center text-danger mb-3">
        <i class="fal fa-5x" [ngClass]="{ 'fa-video-slash': currentStep === Steps.CameraError, 'fa-user-slash': currentStep === Steps.VerificationError }"></i>
      </div>
      <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center" (click)="loadCamera()">
        {{ 'documents.tryAgain' | translate }}
      </button>
      <button type="button" class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center" (click)="selectDesktopMobilePrompt()">
        {{ 'documents.useSmartphoneCamera' | translate }}
      </button>
    </div>
  </div>

  <div class="centermodal__form" *ngIf="currentStep === Steps.MobilePrompt">
    <div class="text-center mb-4">
      <qrcode [cssClass]="'qr-code__canvas'" [qrdata]="currentUrl" [width]="200" [margin]="0" *ngIf="currentUrl"></qrcode>
    </div>
    <div class="qr-code__description">{{ 'documents.accessValidationLink' | translate }}</div>
    <div class="text-center" *ngIf="referenceCode">
      <div class="qr-code__code">{{ referenceCode.reference }}</div>
    </div>
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="goBack()">{{ 'button.back' | translate }}</button>
    <button
      type="button"
      class="centermodal__button centermodal__button--custom-next"
      [disabled]="isLoading"
      (click)="takePictureDesktop()"
      *ngIf="[Steps.Front, Steps.Back, Steps.Selfie].includes($any(currentStep))"
    >
      {{ 'documents.takePhoto' | translate }}
    </button>
  </div>
</div>

<!-- Mobile -->
<div class="modal-body mobile-modal" *ngIf="mobileStep">
  <ng-container [ngTemplateOutlet]="tipsTitleTemplate" *ngIf="currentStep === Steps.Intro"></ng-container>
  <ng-container [ngTemplateOutlet]="hasBackPromptTemplate" *ngIf="currentStep === Steps.Back"></ng-container>
  <ng-container *ngIf="currentStep === Steps.Front">
    <p class="centermodal__title">{{ 'documents.takePhotoOfDocument' | translate }}</p>
    <p class="centermodal__description">{{ 'documents.takeDocumentInImage' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="[Steps.MobileFrontCheck, Steps.MobileBackCheck].includes($any(currentStep))">
    <p class="centermodal__title">{{ 'documents.isPhotoReadable' | translate }}</p>
    <p class="centermodal__description">{{ 'documents.checkIfInformationReadable' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="currentStep === Steps.Selfie">
    <p class="centermodal__title">{{ 'documents.takePhotoOfFace' | translate }}</p>
    <p class="centermodal__description">{{ 'documents.takePhotoOfFaceCompareDocument' | translate }}</p>
  </ng-container>
  <ng-container *ngIf="currentStep === Steps.MobileSelfieCheck">
    <p class="centermodal__title">{{ 'documents.isFaceVisible' | translate }}</p>
    <p class="centermodal__description">{{ 'documents.checkIfFaceVisible' | translate }}</p>
  </ng-container>

  <div class="centermodal__form mx-3 mb-4" *ngIf="currentStep === Steps.Intro">
    <ng-container [ngTemplateOutlet]="tipsContentTemplate"></ng-container>
  </div>

  <div class="centermodal__form">
    <div class="centermodal__button-group centermodal__button-group--vertical mb-5" *ngIf="[Steps.Front, Steps.Back, Steps.Selfie].includes($any(currentStep))">
      <label class="centermodal__button centermodal__button--large centermodal__button--custom-blue justify-content-center mb-3">
        <input
          type="file"
          class="d-none"
          accept="image/*"
          [attr.capture]="currentStep === Steps.Selfie ? 'user' : 'environment'"
          (change)="takePictureMobile($event.target['files'][0]); $any($event.target).value = ''"
        />
        {{ 'documents.' + (currentStep === Steps.Back ? 'takePhotoOfBack' : 'takePhoto') | translate }}
      </label>
      <button
        type="button"
        class="centermodal__button centermodal__button--large centermodal__button--custom-blue-outlined justify-content-center"
        (click)="useFrontAsBackPicture()"
        *ngIf="currentStep === Steps.Back"
      >
        {{ 'documents.noBackPhoto' | translate }}
      </button>
    </div>

    <img
      [src]="picturesUrls[mobileCheckMap[currentStep]] | safeUrl"
      *ngIf="picturesUrls[mobileCheckMap[currentStep]] && [Steps.MobileFrontCheck, Steps.MobileBackCheck, Steps.MobileSelfieCheck].includes($any(currentStep))"
    />
  </div>

  <div class="centermodal__button-group">
    <button type="button" class="centermodal__button" [disabled]="isLoading" (click)="goBack()">{{ 'button.back' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" (click)="setInitialStep()" *ngIf="currentStep === Steps.Intro">{{ 'button.forward' | translate }}</button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" (click)="currentStep = Steps.Back" *ngIf="currentStep === Steps.MobileFrontCheck">
      {{ 'button.forward' | translate }}
    </button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" (click)="currentStep = Steps.Selfie" *ngIf="currentStep === Steps.MobileBackCheck">
      {{ 'button.forward' | translate }}
    </button>
    <button type="button" class="centermodal__button centermodal__button--custom-next" [disabled]="isLoading" (click)="verify(picturesImages)" *ngIf="currentStep === Steps.MobileSelfieCheck">
      {{ 'button.forward' | translate }}
    </button>
  </div>
</div>

<ng-template #tipsTitleTemplate>
  <p class="centermodal__title">{{ 'documents.documentVerification' | translate }}</p>
  <p class="centermodal__description">
    {{ 'documents.' + (verification?.type !== SecurityVerificationEnum.PfFacial ? 'documentVerificationPhotoAndFaceRequired' : 'documentVerificationFacePhotoRequired') | translate }}
  </p>
</ng-template>
<ng-template #tipsContentTemplate>
  <div class="font-weight-bold">{{ 'documents.tips' | translate }}</div>
  <ul class="tips-list">
    <li><i class="fal fa-lightbulb-on"></i> {{ 'documents.findWellLitLocation' | translate }}</li>
    <li><i class="fal fa-eye"></i> {{ 'documents.holdPhoneAtEyeLevel' | translate }}</li>
    <li><i class="fal fa-flashlight"></i> {{ 'documents.avoidStrongBacklights' | translate }}</li>
  </ul>
</ng-template>

<ng-template #hasBackPromptTemplate>
  <p class="centermodal__title">{{ 'documents.hasBackPhoto' | translate }}</p>
  <p class="centermodal__description">
    {{ 'documents.selectBackPhotoOption' | translate }}
  </p>
</ng-template>
