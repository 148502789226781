import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { filter, finalize } from 'rxjs/operators';
import { merge, omit } from 'lodash';

import { untilDestroyed } from '@app/core';
import { slideDownUpTrigger } from '@app/core/animations';
import { AppService, DocumentService, ErrorHandlerService, FolderService, NotyService, OrganizationService, UserService, WhitelabelService } from '@app/services';
import { ContextEnum, Folder, ListFormat, OrganizationMember, SlimDocument, User } from '@app/models';
import { LoaderService } from '@app/shared';
import { DocumentsListingService } from '../shared/documents-listing.service';
import { FoldersListingService } from '../shared/folders-listing.service';

@Component({
  selector: 'app-documents-organization-member-list',
  templateUrl: './documents-organization-member-list.component.html',
  styleUrls: ['./documents-organization-member-list.component.scss'],
  animations: [slideDownUpTrigger]
})
export class DocumentsOrganizationMemberListComponent implements OnInit, OnDestroy {
  ListFormat = ListFormat;
  documents: SlimDocument[];
  folders: Folder[];
  currentUser: User;
  currentMember: OrganizationMember;
  currentFolder: Folder;
  isLoading = false;
  isLastPage = false;
  isLastPageFolders = false;
  pageNumber: number;
  pageNumberFolders: number;
  searchQuery: any;
  mobileSearch = '';
  isOnFolder: boolean;
  isSearchOpen = false;
  openTaxonomyMenu = false;
  isMemberCurrentUser: boolean;
  readonly perPage = 60;

  constructor(
    public route: ActivatedRoute,
    public appService: AppService,
    public documentsListingService: DocumentsListingService,
    public foldersListingService: FoldersListingService,
    public whitelabelService: WhitelabelService,
    private router: Router,
    private translateService: TranslateService,
    private loaderService: LoaderService,
    private userService: UserService,
    private documentService: DocumentService,
    private folderService: FolderService,
    private errorHandlerService: ErrorHandlerService,
    private notyService: NotyService,
    private organizationService: OrganizationService
  ) {}

  ngOnInit() {
    this.documents = [].constructor(7);
    this.isOnFolder = !!this.route.snapshot.params.folderId;
    this.userService.getCurrentUser().subscribe(user => {
      const isPermitted = user && (user.currentPermissions.view_documents_oz || user.currentPermissions.view_member_documents_oz);
      this.currentUser = user;
      if (!user || isPermitted) {
        if (!(this.documents || [])[0]) {
          this.isSearchOpen = this.documentsListingService.isSearchParams(this.route.snapshot.queryParams);
          this.searchQuery = this.documentsListingService.searchQueryFromQueryParams(this.route.snapshot.queryParams);
        }

        if (this.isOnFolder) {
          this.folderService.folder({ id: this.route.snapshot.params.folderId }).subscribe(
            data => (this.currentFolder = data),
            error => this.errorHandlerService.handle(error)
          );
        }
        this.organizationService.organizationMember({ id: this.route.snapshot.params.memberId }).subscribe(member => {
          this.currentMember = member;
          this.isMemberCurrentUser = this.currentMember.user.id === this.currentUser.id;
        });
        this.loadDocuments({ search: this.searchQuery });
        this.loadFolders({ search: this.searchQuery });
      } else {
        this.userService.permissionCheck(isPermitted);
      }
    });

    this.userService
      .watchCurrentUser()
      .pipe(
        filter(user => !!user),
        filter(user => this.currentUser && (this.currentUser.organization.id !== user.organization.id || this.currentUser.member.group.id !== user.member.group.id)),
        untilDestroyed(this)
      )
      .subscribe(() => this.router.navigate(['/']));
  }

  ngOnDestroy() {}

  loadDocuments(options: { page?: number; search?: any } = {}, extraOptions: { showLoader?: boolean } = {}) {
    this.isLoading = true;

    this.pageNumber = options.page || 1;
    const showLoader = extraOptions.showLoader;
    if (showLoader) {
      this.loaderService.show();
    }
    this.documentsListingService.pushSearchQueryToUrl(options.search);

    this.documentService[this.isOnFolder ? 'folderDocuments' : 'organizationMemberDocuments'](this.documentsSearchParams(options.search), this.pageNumber === 1 ? 'cache-and-network' : 'no-cache')
      .pipe(
        finalize(() => {
          this.isLoading = false;
          this.loaderService.hide();
        })
      )
      .subscribe(
        page => {
          this.documents = page ? (this.pageNumber === 1 ? [] : this.documents || []).concat(page.data) : null;
          this.isLastPage = !page || (page.data || []).length < this.perPage;
        },
        error => this.errorHandlerService.handle(error)
      );
  }

  sign(documents: SlimDocument[]) {
    const userDocuments = documents.filter(doc => this.documentsListingService.hasCurrentUser(doc));
    if (userDocuments.length === 0) {
      this.notyService.error(this.translateService.instant('notyService.cannotSignUnauthorized'));
    } else {
      this.documentsListingService.sign(userDocuments).subscribe(
        () => {
          if (userDocuments.filter(doc => (doc as any)._isSelected).length > 0) {
            this.notyService.info(this.translateService.instant('couldnt_sign_documents_selected'));
          }
        },
        error => this.errorHandlerService.handle(error)
      );
    }
  }

  delete(documents: SlimDocument[]) {
    this.documentsListingService.delete(this.documents, documents, { memberId: this.currentMember?.user?.id }).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  transfer(documents: SlimDocument[]) {
    this.documentsListingService.transfer(this.documents, documents, { context: ContextEnum.Organization }).subscribe(
      () => this.loadDocuments({ page: 1, search: this.searchQuery }, { showLoader: true }),
      error => this.errorHandlerService.handle(error)
    );
  }

  toggleBlock(documents: SlimDocument[]) {
    this.documentsListingService.toggleBlock(documents).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  resendWebhook(document: SlimDocument) {
    this.documentService.resendWebhook({ documentId: document.id }).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentResendWebhook')),
      error => this.errorHandlerService.handle(error)
    );
  }

  loadFolders(options: { page?: number; search?: any } = {}) {
    if (this.isOnFolder) {
      this.isLoading = true;
      this.pageNumberFolders = options.page || 1;
      this.folderService
        .folders(
          {
            context: ContextEnum.User,
            memberId: this.route.snapshot.params.memberId,
            parentId: this.route.snapshot.params.folderId,
            search: (options.search || {}).name,
            limit: 14,
            page: this.pageNumberFolders,
            orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection }
          },
          this.pageNumberFolders === 1 ? 'cache-and-network' : 'no-cache'
        )
        .pipe(finalize(() => (this.isLoading = false)))
        .subscribe(
          page => {
            this.folders = (this.pageNumberFolders === 1 ? [] : this.folders || []).concat(page.data);
            this.isLastPageFolders = page.current_page >= page.last_page;
          },
          error => this.errorHandlerService.handle(error)
        );
    }
  }

  openFoldersModal(options: { documents?: SlimDocument[]; folder?: Folder }) {
    if (options.documents) {
      this.documentsListingService
        .openFoldersModal(this.documents, options.documents, {
          context: ContextEnum.Organization,
          currentFolderId: this.route.snapshot.params.folderId
        })
        .subscribe();
    } else if (options.folder) {
      this.foldersListingService.openFoldersModal(this.folders, options.folder).subscribe();
    }
  }

  openCreateFolderModal() {
    // Como só pode criar e editar pastas pra si mesmo, o contexto é USER
    this.foldersListingService.openCreateFolderModal(ContextEnum.User, { parentId: this.currentFolder?.id }).subscribe(
      folder => this.folders.unshift(folder),
      error => this.errorHandlerService.handle(error)
    );
  }

  openUpdateFolderModal(folder: Folder) {
    this.foldersListingService.openUpdateFolderModal(folder).subscribe(newFolder => merge(folder, newFolder));
  }

  deleteFolder(folder: Folder) {
    this.foldersListingService.delete(this.folders, folder).subscribe(
      () => {},
      error => this.errorHandlerService.handle(error)
    );
  }

  resendSignatures(documents: SlimDocument[]) {
    this.documentsListingService.resendSignatures(documents).subscribe(
      () => this.notyService.success(this.translateService.instant('notyService.documentsResentToSignatories')),
      error => this.errorHandlerService.handle(error)
    );
  }

  clearFilters() {
    this.appService.documentStatusFilter = null;
    this.mobileSearch = '';
    this.searchQuery = null;
    this.isSearchOpen = false;
    this.loadDocuments({ page: 1 });
  }

  toggleSearch(value: boolean) {
    this.isSearchOpen = value;
    if (this.isSearchOpen) {
      this.searchQuery = { ...(this.searchQuery || {}), includeArchived: this.isOnFolder, ...(this.route.snapshot.params.memberId ? { memberId: this.route.snapshot.params.memberId } : {}) };
    } else {
      this.clearFilters();
    }
  }

  isAutnq(user?: User) {
    user = user || this.currentUser;
    return user && (this.currentUser.email || '').match(/@autentique.com.br$/);
  }

  exportDocuments(searchQuery: any) {
    if (!this.currentUser.email) {
      this.notyService.info(this.translateService.instant('cant_export_documents_selected_when_email_isnt_registered'));
      return;
    }

    this.isLoading = true;
    this.documentsListingService
      .exportDocuments(omit(this.documentsSearchParams(searchQuery), ['page', 'limit', 'sandbox']))
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe(
        () => {},
        error => this.errorHandlerService.handle(error)
      );
  }

  private documentsSearchParams(searchQuery: any) {
    return {
      folderId: this.route.snapshot.params.folderId,
      memberId: this.route.snapshot.params.memberId,
      status: this.appService.documentStatusFilter,
      limit: this.perPage,
      page: this.pageNumber,
      context: ContextEnum.User,
      sandbox: this.appService.showSandbox,
      orderBy: { field: this.appService.listSorting, direction: this.appService.listDirection },
      ...(Object.keys(searchQuery || {}).length > 0 ? { status: undefined, ...searchQuery } : {})
    };
  }
}
