import { Injectable } from '@angular/core';
import { WatchQueryFetchPolicy } from 'apollo-client';
import { forkJoin, of, throwError, timer } from 'rxjs';
import { defaultIfEmpty, map, mergeMap, retry, switchMap, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

import { environment } from '@env/environment';
import { CredentialsService, throwOnGraphqlError } from '@app/core';
import {
  ContextEnum,
  Document,
  DocumentReportColumnEnum,
  DocumentStatusEnum,
  FloatingField,
  SecurityVerificationEnum,
  SecurityVerificationType,
  SignatureAppearanceEnum,
  SignatureFormat,
  SignatureTypeEnum,
  Signer,
  SignerInput,
  SlimDocument,
  User
} from '@app/models';
import { AppService } from '@app/services/app.service';

import {
  LinkSignatureGQL,
  LinkSignatureQueryVariables,
  PublicDocumentQueryVariables,
  PublicDocumentFileGQL,
  PublicDocumentFileQueryVariables,
  PublicDocumentGQL,
  PublicDocumentHistoryGQL,
  PublicDocumentHistoryQueryVariables,
  PublicSignTokensGQL,
  PublicSignTokensQueryVariables,
  RejectSignatureGQL,
  SignatureGQL,
  SignatureQueryVariables,
  ViewSignatureGQL,
  SignatureBySecurityVerificationCodeGQL,
  SignatureBySecurityVerificationCodeQueryVariables
} from 'src/generated/graphql.public';

import {
  AcceptPadesSignatureGQL,
  AcceptPadesSignatureMutationVariables,
  AcceptCloudCertSignatureGQL,
  AcceptCloudCertSignatureMutationVariables,
  AcceptSignatureGQL,
  ActionEnum,
  AuthDocumentGQL,
  AuthDocumentQueryVariables,
  AuthSignTokensGQL,
  AuthSignTokensQueryVariables,
  ClearDocumentPdfCacheGQL,
  ClearDocumentPdfCacheMutationVariables,
  CreateDocumentGQL,
  CreateDocumentMutationVariables,
  CreateSignerGQL,
  CreateSignerLinkGQL,
  CreateSignerLinkMutationVariables,
  CreateSignerMutationVariables,
  DataSourceGQL,
  DataSourceQueryVariables,
  DeleteDocumentGQL,
  DeleteDocumentMutationVariables,
  DeleteSignerGQL,
  DeleteSignerLinkGQL,
  DeleteSignerLinkMutationVariables,
  DeleteSignerMutationVariables,
  ExportDocumentsGQL,
  ExportDocumentsMutationVariables,
  MergeFilesGQL,
  MergeFilesMutationVariables,
  ResendSignaturesGQL,
  ResendSignaturesMutationVariables,
  DocumentsGQL,
  DocumentsQueryVariables,
  SendSecurityCodeGQL,
  SendSecurityCodeMutationVariables,
  UpdateDocumentGQL,
  UpdateDocumentMutationVariables,
  UpdateSecurityVerificationGQL,
  UpdateSecurityVerificationMutationVariables,
  UpdateSignerGQL,
  UpdateSignerMutationVariables,
  UpdateSignerPositionsGQL,
  UpdateSignerPositionsMutationVariables,
  UploadDocumentFileGQL,
  UploadDocumentFileMutationVariables,
  CheckSecurityVerificationGQL,
  CheckSecurityVerificationMutationVariables,
  GenerateSecurityVerificationCodeGQL,
  GenerateSecurityVerificationCodeMutationVariables,
  ApproveBiometricGQL,
  ApproveBiometricMutationVariables,
  RejectBiometricGQL,
  RejectBiometricMutationVariables,
  LifecycleRescueGQL,
  LifecycleRescueMutationVariables,
  ResendWebhookGQL,
  ResendWebhookMutationVariables
} from 'src/generated/graphql.default';
import { NotyService } from '@app/services/noty.service';

export interface DocumentFlagsOptions {
  ignoreEmailErrors: boolean;
}

@Injectable({ providedIn: 'root' })
export class DocumentService {
  readonly roles: Array<SignerInput['action']> = [
    ActionEnum.Sign,
    ActionEnum.Approve,
    ActionEnum.Recognize,
    ActionEnum.SignAsAWitness,
    ActionEnum.AcknowledgeReceipt,
    ActionEnum.EndorseInBlack,
    ActionEnum.EndorseInWhite
  ];
  readonly signatureAppearanceToFormats = {
    [SignatureAppearanceEnum.Draw]: SignatureFormat.Draw,
    [SignatureAppearanceEnum.Eletronic]: SignatureFormat.Eletronic,
    [SignatureAppearanceEnum.Handwriting]: SignatureFormat.Handwriting,
    [SignatureAppearanceEnum.Image]: SignatureFormat.Image
  };
  readonly allowedManualVerifications = [SecurityVerificationEnum.Manual, SecurityVerificationEnum.Live, SecurityVerificationEnum.Upload, SecurityVerificationEnum.PfFacial];

  constructor(
    public translateService: TranslateService,
    private notyService: NotyService,
    private credentialsService: CredentialsService,
    private appService: AppService,
    private documentsGQL: DocumentsGQL,
    private createDocumentGQL: CreateDocumentGQL,
    private mergeFilesGQL: MergeFilesGQL,
    private updateDocumentGQL: UpdateDocumentGQL,
    private uploadDocumentFileGQL: UploadDocumentFileGQL,
    private authDocumentGQL: AuthDocumentGQL,
    private publicDocumentGQL: PublicDocumentGQL,
    private authSignTokensGQL: AuthSignTokensGQL,
    private publicSignTokensGQL: PublicSignTokensGQL,
    private publicDocumentFileGQL: PublicDocumentFileGQL,
    private signatureGQL: SignatureGQL,
    private linkSignatureGQL: LinkSignatureGQL,
    private resendSignaturesGQL: ResendSignaturesGQL,
    private viewSignatureGQL: ViewSignatureGQL,
    private rejectSignatureGQL: RejectSignatureGQL,
    private acceptSignatureGQL: AcceptSignatureGQL,
    private acceptPadesSignatureGQL: AcceptPadesSignatureGQL,
    private acceptCloudCertSignatureGQL: AcceptCloudCertSignatureGQL,
    private deleteDocumentGQL: DeleteDocumentGQL,
    private deleteSignerGQL: DeleteSignerGQL,
    private clearDocumentPdfCacheGQL: ClearDocumentPdfCacheGQL,
    private createSignerGQL: CreateSignerGQL,
    private updateSignerGQL: UpdateSignerGQL,
    private updateSignerPositionsGQL: UpdateSignerPositionsGQL,
    private createSignerLinkGQL: CreateSignerLinkGQL,
    private deleteSignerLinkGQL: DeleteSignerLinkGQL,
    private dataSourceGQL: DataSourceGQL,
    private publicDocumentHistoryGQL: PublicDocumentHistoryGQL,
    private sendSecurityCodeGQL: SendSecurityCodeGQL,
    private updateSecurityVerificationGQL: UpdateSecurityVerificationGQL,
    private checkSecurityVerificationGQL: CheckSecurityVerificationGQL,
    private generateSecurityVerificationCodeGQL: GenerateSecurityVerificationCodeGQL,
    private signatureBySecurityVerificationCodeGQL: SignatureBySecurityVerificationCodeGQL,
    private exportDocumentsGQL: ExportDocumentsGQL,
    private approveBiometricGQL: ApproveBiometricGQL,
    private rejectBiometricGQL: RejectBiometricGQL,
    private lifecycleRescueGQL: LifecycleRescueGQL,
    private resendWebhookGQL: ResendWebhookGQL
  ) {}

  unarchivedDocuments(variables: Omit<DocumentsQueryVariables, 'context' | 'folderId' | 'groupUuid' | 'memberId'>, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService
      .watchCacheAndNetworkBase(this.documentsGQL.watch({ context: ContextEnum.User, ...variables }, { fetchPolicy }).valueChanges, fetchPolicy)
      .pipe(map(response => response.data.documents));
  }

  organizationDocuments(variables: Omit<DocumentsQueryVariables, 'context' | 'folderId' | 'groupUuid' | 'memberId'>, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService
      .watchCacheAndNetworkBase(this.documentsGQL.watch({ context: ContextEnum.Organization, ...variables }, { fetchPolicy }).valueChanges, fetchPolicy)
      .pipe(map(response => response.data.documents));
  }

  organizationMemberDocuments(variables: Omit<DocumentsQueryVariables, 'context' | 'folderId' | 'groupUuid'>, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService
      .watchCacheAndNetworkBase(this.documentsGQL.watch({ context: ContextEnum.User, ...variables }, { fetchPolicy }).valueChanges, fetchPolicy)
      .pipe(map(response => response.data.documents));
  }

  organizationGroupDocuments(variables: Omit<DocumentsQueryVariables, 'context' | 'folderId' | 'groupUuid'>, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService
      .watchCacheAndNetworkBase(this.documentsGQL.watch({ context: ContextEnum.Group, ...variables }, { fetchPolicy }).valueChanges, fetchPolicy)
      .pipe(map(response => response.data.documents));
  }

  folderDocuments(variables: Omit<DocumentsQueryVariables, 'context' | 'groupUuid'>, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService
      .watchCacheAndNetworkBase(this.documentsGQL.watch({ context: ContextEnum.User, ...variables }, { fetchPolicy }).valueChanges, fetchPolicy)
      .pipe(map(response => response.data.documents));
  }

  trashedDocuments(variables: Omit<DocumentsQueryVariables, 'context' | 'folderId' | 'groupUuid' | 'memberId'>, fetchPolicy: WatchQueryFetchPolicy = 'no-cache') {
    return this.appService
      .watchCacheAndNetworkBase(this.documentsGQL.watch({ context: ContextEnum.User, ...variables, status: DocumentStatusEnum.Deleted }, { fetchPolicy }).valueChanges, fetchPolicy)
      .pipe(map(response => response.data.documents));
  }

  exportDocuments(variables: Omit<ExportDocumentsMutationVariables, 'columns'>) {
    const columns: DocumentReportColumnEnum[] = [
      DocumentReportColumnEnum.Name,
      DocumentReportColumnEnum.Author,
      DocumentReportColumnEnum.Status,
      DocumentReportColumnEnum.CreatedAt,
      DocumentReportColumnEnum.Signatures,
      DocumentReportColumnEnum.Link
    ];
    return this.exportDocumentsGQL.mutate({ columns, ...variables }, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.documentReport)
    );
  }

  document(variables: AuthDocumentQueryVariables | PublicDocumentQueryVariables) {
    const gqlInstance = this.credentialsService.isAuthenticated() ? this.authDocumentGQL : this.publicDocumentGQL;
    if (variables.id.length < 49) {
      variables.publicId = variables.id;
      delete variables.id;
    }
    return (gqlInstance as AuthDocumentGQL).fetch(variables).pipe(
      throwOnGraphqlError(),
      tap(response => {
        if ((response.data || {}).document === null) {
          response.errors = [{ message: 'document_not_found', name: null, locations: null, extensions: null, nodes: null, path: null, source: null, positions: null, originalError: null }];
          throw response;
        }
      }),
      map(response => response.data.document),
      map(doc => {
        // ESCONDE GEOLOCATION PARA EVENTOS QUE FICARAM COM IPS ERRADOS
        doc.signatures.forEach(signer => {
          const dateInterval = [new Date('2021-05-30T21:27:00-0300'), new Date('2021-05-31T13:10:00-0300')];
          ['viewed', 'rejected', 'signed'].forEach(action => {
            if ((signer[action] || {}).created_at && new Date(signer[action].created_at) >= dateInterval[0] && new Date(signer[action].created_at) <= dateInterval[1]) {
              delete signer[action].geolocation;
            }
          });
        });
        return doc;
      })
    );
  }

  signature(variables: SignatureQueryVariables) {
    return this.signatureGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.signature)
    );
  }

  linkSignature(variables: LinkSignatureQueryVariables) {
    return this.linkSignatureGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.signatureByLink)
    );
  }

  authSignToken(variables: AuthSignTokensQueryVariables) {
    if (variables && (variables.documentsIds || []).length > 0) {
      return this.authSignTokensGQL.fetch(variables).pipe(
        throwOnGraphqlError(),
        map(response => response.data.signatures)
      );
    } else {
      return of([]);
    }
  }

  publicSignToken(variables: PublicSignTokensQueryVariables) {
    return this.publicSignTokensGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.signatures)
    );
  }

  documentFile(variables: PublicDocumentFileQueryVariables) {
    return this.publicDocumentFileGQL.fetch(variables, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.document)
    );
  }

  getDataSource(variables: DataSourceQueryVariables) {
    return this.dataSourceGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.dataSource)
    );
  }

  signatureBySecurityVerificationCode(variables: SignatureBySecurityVerificationCodeQueryVariables) {
    return this.signatureBySecurityVerificationCodeGQL.fetch(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.signatureBySecurityVerificationCode)
    );
  }

  mergeFiles(variables: MergeFilesMutationVariables) {
    return this.mergeFilesGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.mergeFiles)
    );
  }

  create(variables: CreateDocumentMutationVariables) {
    return this.createDocumentGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createDocument)
    );
  }

  update(variables: UpdateDocumentMutationVariables) {
    return this.updateDocumentGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateDocument)
    );
  }

  uploadFile(variables: UploadDocumentFileMutationVariables) {
    return this.uploadDocumentFileGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.uploadFile)
    );
  }

  createSigner(variables: CreateSignerMutationVariables) {
    return this.createSignerGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createSigner)
    );
  }

  updateSigner(variables: UpdateSignerMutationVariables) {
    return this.updateSignerGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.changeSignature)
    );
  }

  updateSignersPositions(signersVariables: UpdateSignerPositionsMutationVariables[]) {
    return forkJoin(
      signersVariables.map(variables => {
        return this.updateSignerPositionsGQL.mutate(variables).pipe(
          throwOnGraphqlError(),
          map(response => response.data.updateSignature)
        );
      })
    );
  }

  overwriteSigner(variable: UpdateSignerPositionsMutationVariables) {
    return this.updateSignerPositionsGQL.mutate(variable).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateSignature)
    );
  }

  resendSignatures(variables: ResendSignaturesMutationVariables) {
    return this.resendSignaturesGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.resendSignatures)
    );
  }

  view(signatureIds: string[]) {
    return forkJoin(
      signatureIds.map(id => {
        return this.viewSignatureGQL.mutate({ signatureId: id }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.viewSignature)
        );
      })
    );
  }

  sign(ids: string[]) {
    return forkJoin(
      ids.map(id => {
        return this.acceptSignatureGQL.mutate({ id }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.acceptSignature)
        );
      })
    );
  }

  signPades(variables: AcceptPadesSignatureMutationVariables) {
    return this.acceptPadesSignatureGQL.mutate({ ...variables }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.acceptSignature)
    );
  }

  signCloudCert(variables: AcceptCloudCertSignatureMutationVariables) {
    return this.acceptCloudCertSignatureGQL.mutate({ ...variables }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.acceptSignature)
    );
  }

  reject(signatureIds: string[], type: SignatureTypeEnum, reason?: string) {
    return forkJoin(
      signatureIds.map(id => {
        return this.rejectSignatureGQL.mutate({ signatureId: id, type, reason: reason || null }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.rejectSignature)
        );
      })
    );
  }

  approveBiometric(variables: ApproveBiometricMutationVariables) {
    return this.approveBiometricGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.approveBiometric)
    );
  }

  rejectBiometric(variables: RejectBiometricMutationVariables) {
    return this.rejectBiometricGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.rejectBiometric)
    );
  }

  delete(ids: string[], variables?: Omit<DeleteDocumentMutationVariables, 'id'> & { block?: boolean }) {
    const block = variables && variables.block;
    delete (variables || {}).block;
    const mutations = ids.map(id => {
      return (block ? this.block([id]) : of(null)).pipe(
        switchMap(() => this.deleteDocumentGQL.mutate({ id, ...(variables || {}) })),
        throwOnGraphqlError(),
        map(response => response.data.deleteDocument)
      );
    });
    return mutations.length > 0 ? forkJoin(mutations) : of(null);
  }

  deleteSigner(variables: DeleteSignerMutationVariables) {
    return this.deleteSignerGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.deleteSigner)
    );
  }

  createSignerLink(variables: CreateSignerLinkMutationVariables) {
    return this.createSignerLinkGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.createLinkToSignature)
    );
  }

  deleteSignerLink(variables: DeleteSignerLinkMutationVariables) {
    return this.deleteSignerLinkGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.revokeLinkFromSignature)
    );
  }

  clearPdfCache(variables: ClearDocumentPdfCacheMutationVariables) {
    return this.clearDocumentPdfCacheGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.clearDocumentCache)
    );
  }

  sendSecurityCode(variables: SendSecurityCodeMutationVariables) {
    return this.sendSecurityCodeGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.sendSecurityCode)
    );
  }

  checkSecurityVerification(variables: CheckSecurityVerificationMutationVariables) {
    return this.checkSecurityVerificationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.checkSecurityVerification)
    );
  }

  updateSecurityVerification(variables: UpdateSecurityVerificationMutationVariables) {
    return this.updateSecurityVerificationGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.updateSecurityVerification)
    );
  }

  generateSecurityVerificationCode(variables: GenerateSecurityVerificationCodeMutationVariables) {
    return this.generateSecurityVerificationCodeGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.generateSecurityVerificationCode)
    );
  }

  block(ids: string[]) {
    return forkJoin(
      ids.map(id =>
        this.updateDocumentGQL.mutate({ id, document: { deadline_at: new Date().toJSON() } }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.updateDocument)
        )
      )
    ).pipe(defaultIfEmpty([]));
  }

  unblock(ids: string[]) {
    return forkJoin(
      ids.map(id =>
        this.updateDocumentGQL.mutate({ id, document: { deadline_at: null } }).pipe(
          throwOnGraphqlError(),
          map(response => response.data.updateDocument)
        )
      )
    ).pipe(defaultIfEmpty([]));
  }

  lifecycleRescue(variables: LifecycleRescueMutationVariables) {
    return this.lifecycleRescueGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.lifecycleRescue)
    );
  }

  resendWebhook(variables: ResendWebhookMutationVariables) {
    return this.resendWebhookGQL.mutate(variables).pipe(
      throwOnGraphqlError(),
      map(response => response.data.resendWebhook)
    );
  }

  signedDocumentUrl(document: SlimDocument | Document) {
    return document ? document.files?.signed || environment.serverUrl + '/documentos/' + document.id + '/assinado.pdf' : '';
  }

  padesDocumentUrl(document: SlimDocument | Document) {
    return document ? document.files?.pades || environment.serverUrl + '/documentos/' + document.id + '/pades.pdf' : '';
  }

  isSignerSigned(signer: SlimDocument['signatures'][0] | Document['signatures'][0], options?: DocumentFlagsOptions) {
    return !!(signer?.action && ((signer.signed || {}).created_at || this.isSignerBiometricApproved(signer)) && !this.isSignerRejected(signer, options));
  }

  isSignerRejected(signer: SlimDocument['signatures'][0] | Document['signatures'][0], options?: DocumentFlagsOptions) {
    return (
      signer?.action &&
      ((signer.rejected || {}).created_at ||
        (!(options || {}).ignoreEmailErrors &&
          !(signer.email_events || {}).delivered &&
          !(signer.viewed || {}).created_at &&
          !(signer.signed || {}).created_at &&
          (signer.email_events || {}).refused &&
          !(signer.email_events.reason || '').match(/deferred/i)) ||
        this.isSignerBiometricRejected(signer))
    );
  }

  isSignerPending(signer: SlimDocument['signatures'][0] | Document['signatures'][0], options?: DocumentFlagsOptions) {
    return !this.isSignerSigned(signer) && !this.isSignerRejected(signer, options);
  }

  isSignerUnapproved(signer: SlimDocument['signatures'][0] | Document['signatures'][0]) {
    return !!(signer?.action && (signer.signed_unapproved || {}).created_at && !(signer.biometric_approved || {}).created_at && !(signer.biometric_rejected || {}).created_at);
  }

  isSignerBiometricApproved(signer: SlimDocument['signatures'][0] | Document['signatures'][0]) {
    return !!(signer?.action && (signer.signed_unapproved || {}).created_at && (signer.biometric_approved || {}).created_at && !(signer.biometric_rejected || {}).created_at);
  }

  isSignerBiometricRejected(signer: SlimDocument['signatures'][0] | Document['signatures'][0]) {
    return !!(signer?.action && (signer.signed_unapproved || {}).created_at && (signer.biometric_rejected || {}).created_at);
  }

  isSigned(document: SlimDocument | Document) {
    const signersCount = document.signatures.filter(signer => signer.action).length;
    return signersCount > 0 && signersCount === document.signatures.filter(signer => this.isSignerSigned(signer)).length;
  }

  isRejected(document: SlimDocument | Document, options?: DocumentFlagsOptions) {
    return document.signatures.filter(signer => this.isSignerRejected(signer, options)).length > 0;
  }

  isExpired(document: SlimDocument | Document) {
    return this.hasExpirationPassed(document) && this.isSigned(document);
  }

  isPending(document: SlimDocument | Document) {
    return !this.isSigned(document) && !this.isRejected(document);
  }

  isPastDeadline(document: SlimDocument | Document) {
    return !!document && !!document.deadline_at && new Date(document.deadline_at) <= new Date();
  }

  isPastLifecycle(document: SlimDocument | Document) {
    // @ts-ignore
    return !!document && !!document.lifecycle_in && new Date(document.lifecycle_in) <= new Date();
  }

  isSignerTurn(document: SlimDocument | Document, signer?: SlimDocument['signatures'][0] | Document['signatures'][0], options: DocumentFlagsOptions = { ignoreEmailErrors: true }) {
    if (document && document.sortable) {
      const nextSignerIndex = this.getNextSignerIndex(document, options);
      const nextSigner = document.signatures[nextSignerIndex];
      const nextSigners = [];

      if (nextSigner) {
        nextSigners.push(nextSigner); // Pode ser signatário sem email, então o primeiro signatário é obrigatório
        for (let i = nextSignerIndex + 1; i < document.signatures.length; i++) {
          if (nextSigner.email && document.signatures[i].email === nextSigner.email) {
            nextSigners.push(document.signatures[i]);
          } else {
            break;
          }
        }
      }

      return !!nextSigners.find(sig => signer && sig.public_id === signer.public_id);
    } else {
      return true;
    }
  }

  isSignedOnce(document: SlimDocument | Document) {
    return !!document && !!document.signatures.find(signer => this.isSignerSigned(signer));
  }

  getNextSignerIndex(document: SlimDocument | Document, options: DocumentFlagsOptions = { ignoreEmailErrors: true }) {
    return document.signatures.findIndex(sig => sig.action && ((document.stop_on_rejected && this.isSignerRejected(sig, options)) || this.isSignerPending(sig, options)));
  }

  getCurrentSigners(document: SlimDocument, currentUser: User) {
    return (document && currentUser && (document.signatures || []).filter(sig => sig.action && sig.user && currentUser && sig.user.id === currentUser.id)) || [];
  }

  documentWithFloatingFields(variables: AuthDocumentQueryVariables) {
    return this.document(variables).pipe(map(doc => ({ document: doc, floatingFields: this.floatingFieldsFromDocument(doc) })));
  }

  retryDocumentFile(variables: PublicDocumentFileQueryVariables) {
    return this.documentFile(variables).pipe(
      mergeMap(data => (!data.files?.original ? timer(5000).pipe(mergeMap(() => throwError(null))) : of(data))),
      retry(50)
    );
  }

  documentHistory(variables: PublicDocumentHistoryQueryVariables) {
    return this.publicDocumentHistoryGQL.fetch(variables, { fetchPolicy: 'no-cache' }).pipe(
      throwOnGraphqlError(),
      map(response => response.data.documentEmailHistory)
    );
  }

  floatingFieldsFromDocument(doc: Document) {
    const floatingFields: FloatingField[] = [];
    doc.signatures.forEach(signer => {
      if ((signer.positions || []).length > 0 && this.isSignerSigned(signer)) {
        const forcedFormat = doc && doc.configs && doc.configs.signature_appearance && this.signatureAppearanceToFormats[doc.configs.signature_appearance];
        signer.positions.forEach(position => {
          floatingFields.push({
            element: position.element,
            signature: {
              name: signer.configs?.overwrite_name || signer.user.name,
              email: signer.user.email,
              cpf: signer.user.cpf,
              created_at: signer.signed?.created_at || signer.signed_unapproved?.created_at,
              public_id: signer.public_id,
              actionName: signer.action,
              format: forcedFormat ? forcedFormat : signer.user.settings ? signer.user.settings.format : SignatureFormat.Handwriting,
              initialsFormat: forcedFormat ? forcedFormat : signer.user.settings ? signer.user.settings.initials_format : SignatureFormat.Handwriting,
              font: signer.user.settings ? signer.user.settings.font : '',
              signatureImageUrl: signer.user.settings ? signer.user.settings.signature : null,
              initialsImageUrl: signer.user.settings ? signer.user.settings.initials : null,
              signatureDrawImageUrl: signer.user.settings ? signer.user.settings.signature_draw : null,
              initialsDrawImageUrl: signer.user.settings ? signer.user.settings.initials_draw : null,
              certificate: signer.certificate
                ? {
                    name: signer.certificate.name,
                    type: signer.certificate.type,
                    email: signer.certificate.email,
                    document: signer.certificate.document
                  }
                : null
            },
            position: { x: parseFloat(position.x), y: parseFloat(position.y), z: position.z, angle: position.angle }
          });
        });
      }
    });
    return floatingFields;
  }

  getPendingManualVerification(signer: SlimDocument['signatures'][0] | Document['signatures'][0]): SecurityVerificationType {
    return ((signer || {}).verifications || [])
      .sort(value => (value.verified_at ? 1 : -1))
      .find(
        // Prioriza verified_at undefined
        value => !!value && this.allowedManualVerifications.includes(value.type)
      );
  }

  hasCurrentUser(user: User, document: SlimDocument) {
    return !!(document && (document.signatures || []).find(sig => sig.user && user && sig.user.id === user.id));
  }

  isAllowedToArchive(user: User, document?: SlimDocument) {
    return user && user.currentPermissions.archive_documents && (!document || this.hasCurrentUser(user, document) || this.isAllowedToManage(user, document));
  }

  isAllowedToDelete(user: User, document?: SlimDocument) {
    return user && user.currentPermissions.delete_documents && (!document || this.hasCurrentUser(user, document) || this.isAllowedToManage(user, document));
  }

  isAllowedToResendWebhook(user?: User, document?: SlimDocument) {
    return user && user.organization.settings?.webhook_url?.length > 0 && this.isAdmin(user, document);
  }

  isAllowedToManage(user: User, document?: SlimDocument) {
    if (user && document) {
      const isGroupDoc = !!document.signatures.find(sig => sig.group_id && sig.group_id === user.member.group.id);
      const isOrgDoc = !!document.signatures.find(sig => sig.organization_id && sig.organization_id === user.organization.id);

      return (user.currentPermissions.actions_documents_gr && isGroupDoc) || (user.currentPermissions.actions_documents_oz && isOrgDoc);
    } else if (user && !document) {
      return user.currentPermissions.actions_documents_oz;
    }
    return false;
  }

  isAdmin(user: User, document: SlimDocument) {
    const author = document.signatures.find(sig => sig?.user?.id === document.author.id);

    const isAuthor = user.id === author?.user?.id;
    const isOrganizationAdmin = user.currentPermissions.actions_documents_oz && user.organization.id === author?.organization_id;
    const isGroupAdmin = user.currentPermissions.actions_documents_gr && user.organization.id === author?.organization_id && author?.group_id === user.member.group.id;

    return isAuthor || isOrganizationAdmin || isGroupAdmin;
  }

  translateRole(action: Signer['action']) {
    return this.translateService.instant(`sign_role_${((action && action.name) || 'SIGN').toLowerCase()}`);
  }

  copySignatureLink(signer: Signer): void {
    this.notyService.success(
      this.translateService.instant('notyService.signerLinkCopied', {
        signerName: signer?.name || signer?.user?.name || signer?.email || signer?.user?.email || signer?.user?.phone
      }),
      2000
    );
  }

  private hasExpirationPassed(document: SlimDocument | Document) {
    return document.expiration_at && new Date() > new Date(document.expiration_at);
  }
}
