<label class="field__holder" [class.error]="validationErrors?.document?.name">
  <input class="field none" type="text" [class.empty]="!document.name" [(ngModel)]="document.name" />
  <span class="field__placeholder">{{ 'documents.documentName' | translate }}</span>
  <p [hidden]="!validationErrors?.document?.name" class="field__error-message">{{ validationErrors?.document?.name }}</p>
</label>

<label
  class="field__holder textarea"
  [class.error]="validationErrors?.document?.message"
  #DocumentMessageTooltip="ngbTooltip"
  triggers="focus"
  [autoClose]="true"
  [ngbTooltip]="tipDocumentMessage"
  container="body"
  placement="right"
>
  <textarea class="field none" type="text" [class.empty]="false" [(ngModel)]="document.message" (focus)="DocumentMessageTooltip.toggle()"></textarea>
  <span class="field__placeholder">{{ 'documents.messageToSignatories' | translate }} </span>
  <p [hidden]="!validationErrors?.document?.message" class="field__error-message">{{ validationErrors?.document?.message }}</p>
</label>

<label class="field__holder justify-content-center" *ngIf="isNewDocument">
  <input class="field text-dark" type="text" disabled [value]="folder?.name || ('documents.noFolder' | translate)" />
  <span class="field__placeholder">{{ 'documents.archiveIn' | translate }}</span>
  <span *ngIf="whitelabelService.params.folderBreadCrumbs" class="field__placeholder-fixed" (click)="openFoldersModal()">{{ 'button.select' | translate }}</span>
</label>

<ng-container *ngIf="!document.qualified || (document.qualified && isNewDocument)">
  <div class="fields-separator" (click)="collapseAppearanceConfigs.toggle()" [attr.aria-expanded]="!isCollapsedAppearanceConfigs">
    {{ 'documents.appearance' | translate }} <i class="fal fa-chevron-{{ isCollapsedAppearanceConfigs ? 'down' : 'up' }}"></i>
  </div>
  <div class="fields-wrap" #collapseAppearanceConfigs="ngbCollapse" [(ngbCollapse)]="isCollapsedAppearanceConfigs">
    <app-select
      placeholder="{{ 'appSelect.signatureAppearance' | translate }}"
      [disableSearch]="true"
      [disabled]="document.locale.country !== 'BR'"
      [(ngModel)]="document.new_signature_style"
      [options]="[
        { key: false, value: 'appSelect.signatureOnly' | translate, height: 46, fontSize: 16, tooltip: tipDocumentOldStyle },
        { key: true, value: 'appSelect.signatoryDataField' | translate, height: 46, fontSize: 16, tooltip: tipDocumentNewSignatureStyle }
      ]"
      (ngModelChange)="selectNewSignatureStyle(document.new_signature_style)"
    >
    </app-select>
    <div
      class="document-signers-sort"
      [ngbTooltip]="document.qualified ? (document.new_signature_style ? tipDocumentEletronicMarker : tipQualifiedEletronicMarker) : tipDocumentEletronicMarker"
      container="body"
      placement="right"
    >
      <label class="document-signers-sort-label">
        <app-switch [(ngModel)]="document.footer" [disableInput]="!showNewSignatureStyle && isQualified" (change)="switchChangeFooter()"></app-switch>
        <span class="document-signers-sort-text" [ngClass]="{ disabled: !showNewSignatureStyle && isQualified }">{{ 'documents.insertElectronicAuthenticationMarks' | translate }}</span>
      </label>
    </div>
    <app-select
      *ngIf="document.footer"
      style="margin-top: .8rem; max-width: 12rem;"
      placeholder="{{ 'appSelect.position' | translate }}"
      [disableSearch]="true"
      [(ngModel)]="document.footer"
      [options]="[
        { key: FooterEnum.Bottom, value: 'appSelect.footer' | translate, height: 46, fontSize: 16 },
        { key: FooterEnum.Left, value: 'appSelect.leftMargin' | translate, height: 46, fontSize: 16 },
        { key: FooterEnum.Right, value: 'appSelect.rightMargin' | translate, height: 46, fontSize: 16 }
      ]"
      (ngModelChange)="selectNewSignatureStyle(!!document.footer)"
    >
    </app-select>
    <hr />

    <div class="document-signers-sort" [ngbTooltip]="tipDocumentForceSignatureAppearance" container="body" placement="right" *ngIf="isNewDocument && document.configs">
      <label class="document-signers-sort-label">
        <app-switch [(ngModel)]="document.configs.signature_appearance" (change)="switchSignatureAppearance()" [disableInput]="!currentUser?.subscription.has_premium_features"></app-switch>
        <span class="document-signers-sort-text pro" [attr.data-pro-text]="translateService.instant('shared.pro')">{{ 'documents.forceSignatureAppearance' | translate }}</span>
      </label>
    </div>
    <app-select
      *ngIf="isNewDocument && document.configs?.signature_appearance"
      style="margin-top: .8rem; max-width: 9rem;"
      placeholder="{{ 'appSelect.appearance' | translate }}"
      [disableSearch]="true"
      [(ngModel)]="document.configs.signature_appearance"
      [options]="[
        { key: SignatureAppearanceEnum.Handwriting, value: 'appSelect.simulated' | translate, height: 46, fontSize: 16 },
        { key: SignatureAppearanceEnum.Eletronic, value: 'appSelect.electronic' | translate, height: 46, fontSize: 16 },
        { key: SignatureAppearanceEnum.Image, value: 'appSelect.image' | translate, height: 46, fontSize: 16 },
        { key: SignatureAppearanceEnum.Draw, value: 'appSelect.draw' | translate, height: 46, fontSize: 16 }
      ]"
    >
    </app-select>
  </div>
</ng-container>

<div class="fields-separator" (click)="collapseEmailConfigs.toggle()" [attr.aria-expanded]="!isCollapsedEmailConfigs">
  <span class="pro" [attr.data-pro-text]="translateService.instant('shared.pro')">{{ 'documents.emails' | translate }}</span>
  <i class="fal fa-chevron-{{ isCollapsedEmailConfigs ? 'down' : 'up' }}"></i>
</div>
<div class="fields-wrap" #collapseEmailConfigs="ngbCollapse" [(ngbCollapse)]="isCollapsedEmailConfigs">
  <div class="document-signers-sort" #DocumentNotificationFinished="ngbTooltip" [ngbTooltip]="tipDocumentNotificationFinished" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentNotificationFinished.toggle()" *ngIf="document?.configs">
      <app-switch [(ngModel)]="document.configs.notification_finished" [disableInput]="!currentUser?.subscription.has_premium_features"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.completionDocumentNotification' | translate }}</span>
    </label>
  </div>
  <hr />
  <div class="document-signers-sort" #DocumentNotificationSigned="ngbTooltip" [ngbTooltip]="tipDocumentNotificationSigned" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentNotificationSigned.toggle()" *ngIf="document?.configs">
      <app-switch [(ngModel)]="document.configs.notification_signed" [disableInput]="!currentUser?.subscription.has_premium_features"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.sendEmailToSignatoryAfterSigning' | translate }}</span>
    </label>
  </div>
  <hr />
  <label
    class="field__holder"
    [class.error]="validationErrors?.document?.cc"
    #DocumentCCTooltip="ngbTooltip"
    triggers="focus"
    [autoClose]="true"
    [ngbTooltip]="tipDocumentCC"
    container="body"
    placement="right"
  >
    <input
      class="field none"
      type="text"
      [class.empty]="!cc"
      style="padding-right: 45px"
      [(ngModel)]="cc"
      [disabled]="!currentUser?.subscription.has_premium_features"
      (focus)="DocumentCCTooltip.toggle()"
    />
    <span class="field__placeholder">{{ 'documents.ccs' | translate }}</span>
    <div
      class="signer-options-advanced-option center-input-floating-button"
      [class.active]="isLockedCC"
      [class.disabled]="!currentUser?.subscription.has_premium_features"
      (click)="isLockedCC = !isLockedCC; appService.lockedCC = isLockedCC ? $any(cc) : ''"
      *ngIf="isNewDocument"
    >
      <i class="fal fa-lock-alt"></i>
    </div>
  </label>
  <label
    class="field__holder"
    [class.error]="validationErrors?.document?.reply_to"
    #DocumentReplyToTooltip="ngbTooltip"
    triggers="focus"
    [autoClose]="true"
    [ngbTooltip]="tipDocumentReplyTo"
    container="body"
    placement="right"
  >
    <input
      class="field none"
      type="text"
      [class.empty]="!replyTo"
      style="padding-right: 45px"
      [(ngModel)]="replyTo"
      [disabled]="!currentUser?.subscription.has_premium_features || !isNewDocument"
      (focus)="DocumentReplyToTooltip.toggle()"
      appValidEmail
    />
    <span class="field__placeholder">{{ 'documents.replyToEmail' | translate }}</span>
    <div
      class="signer-options-advanced-option center-input-floating-button"
      [class.active]="isLockedReplyTo"
      [class.disabled]="!currentUser?.subscription.has_premium_features"
      (click)="isLockedReplyTo = !isLockedReplyTo; appService.lockedReplyTo = isLockedReplyTo ? $any(replyTo) : ''"
      *ngIf="isNewDocument"
    >
      <i class="fal fa-lock-alt"></i>
    </div>
  </label>
</div>

<div class="fields-separator" (click)="collapseReminderConfigs.toggle()" [attr.aria-expanded]="!isCollapsedReminderConfigs">
  <span class="pro" [attr.data-pro-text]="translateService.instant('shared.pro')">{{ 'documents.reminders' | translate }}</span>
  <i class="fal fa-chevron-{{ isCollapsedReminderConfigs ? 'down' : 'up' }}"></i>
</div>
<div class="sidebar-options-more fields-wrap" #collapseReminderConfigs="ngbCollapse" [(ngbCollapse)]="isCollapsedReminderConfigs">
  <div class="document-signers-sort" #DocumentAlertSignatureTooltip="ngbTooltip" [ngbTooltip]="tipDocumentAlertSignature" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentAlertSignatureTooltip.toggle()">
      <app-switch [(ngModel)]="showReminder" (change)="switchChangeReminder()" [disableInput]="!currentUser?.subscription.has_premium_features"></app-switch>
      <span class="document-signers-sort-text"> {{ 'documents.signatureReminder' | translate }}</span>
    </label>
  </div>
  <app-select
    *ngIf="showReminder"
    style="margin-top: .8rem; max-width: 11rem;"
    placeholder="{{ 'appSelect.frequency' | translate }}"
    [disableSearch]="true"
    [(ngModel)]="document.reminder"
    [options]="[
      { key: ReminderEnum.Weekly, value: 'appSelect.weekly' | translate, height: 46, fontSize: 16 },
      { key: ReminderEnum.Daily, value: 'appSelect.daily' | translate, height: 46, fontSize: 16 }
    ]"
  >
  </app-select>
  <hr />

  <div class="document-signers-sort" #DocumentAlertOverdueTooltip="ngbTooltip" [ngbTooltip]="tipDocumentAlertOverdue" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentAlertOverdueTooltip.toggle()">
      <app-switch [(ngModel)]="showExpiration" (change)="switchChangeExpiration()" [disableInput]="!currentUser?.subscription.has_premium_features"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.expirationReminder' | translate }}</span>
    </label>
  </div>

  <label class="field__holder select mt-2" *ngIf="showExpiration">
    <select class="field" [(ngModel)]="document.expiration.days_before" [class.empty]="false" [disabled]="!currentUser?.subscription.has_premium_features">
      <option [ngValue]="index + 1" *ngFor="let item of [].constructor(30); let index = index">{{ index + 1 }} </option>
    </select>
    <p class="field__placeholder">{{ 'documents.reminderInterval' | translate }}</p>
  </label>

  <label class="field__holder" [class.error]="validationErrors?.document?.expiration?.notify_at" *ngIf="showExpiration">
    <input
      class="field"
      [(ngModel)]="document.expiration.notify_at"
      [class.empty]="!document.expiration.notify_at"
      [disabled]="!currentUser?.subscription.has_premium_features"
      mask="00/00/0000"
      [dropSpecialCharacters]="false"
      [specialCharacters]="['/']"
    />
    <p class="field__placeholder">{{ 'documents.expirationDate' | translate }}</p>
  </label>
</div>

<div class="fields-separator" (click)="collapseMiscellaneousConfigs.toggle()" [attr.aria-expanded]="!isCollapsedMiscellaneousConfigs">
  {{ 'documents.otherSettings' | translate }} <i class="fal fa-chevron-{{ isCollapsedMiscellaneousConfigs ? 'down' : 'up' }}"></i>
</div>
<div class="sidebar-options-more fields-wrap" #collapseMiscellaneousConfigs="ngbCollapse" [(ngbCollapse)]="isCollapsedMiscellaneousConfigs">
  <div class="document-signers-sort" *ngIf="document?.locale?.country === 'BR'" #DocumentRequireCPFTooltip="ngbTooltip" [ngbTooltip]="tipDocumentRequireCPF" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentRequireCPFTooltip.toggle()">
      <app-switch [(ngModel)]="document.ignore_cpf"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.doNotRequireCPF' | translate }}</span>
    </label>
  </div>
  <hr *ngIf="document?.locale?.country === 'BR'" />
  <div class="document-signers-sort" #DocumentRefuseTooltip="ngbTooltip" [ngbTooltip]="tipDocumentRefuse" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentRefuseTooltip.toggle()">
      <app-switch [(ngModel)]="document.refusable" [disableInput]="showReminder"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.allowDocumentRejection' | translate }}</span>
    </label>
  </div>
  <hr />
  <div [class.d-none]="!document.refusable">
    <label class="document-signers-sort-label">
      <app-switch [(ngModel)]="document.stop_on_rejected"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.preventOthersFromSigningAfterRejection' | translate }}</span>
    </label>
    <hr />
  </div>
  <div class="document-signers-sort" #DocumentDeadlineTooltip="ngbTooltip" [ngbTooltip]="tipDocumentDeadline" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentDeadlineTooltip.toggle()">
      <app-switch [(ngModel)]="showDeadline" (change)="switchChangeDeadline()"></app-switch>
      <span class="document-signers-sort-text">{{ 'documents.lockDocumentAfterDeadline' | translate }}</span>
    </label>
  </div>
  <label class="field__holder mt-2" [class.error]="validationErrors?.document?.deadline_at" style="width: 130px; display: inline-block;" *ngIf="showDeadline">
    <input class="field" [(ngModel)]="deadline.date" [class.empty]="!deadline.date" mask="00/00/0000" [dropSpecialCharacters]="false" [specialCharacters]="['/']" />
    <p class="field__placeholder">{{ 'documents.date' | translate }}</p>
  </label>
  <label class="field__holder mt-2" [class.error]="validationErrors?.document?.deadline_at" style="width: 80px; display: inline-block;" *ngIf="showDeadline">
    <input class="field" [(ngModel)]="deadline.time" [class.empty]="!deadline.time" mask="00:00" [dropSpecialCharacters]="false" [specialCharacters]="[':']" />
    <p class="field__placeholder">{{ 'documents.time' | translate }}</p>
  </label>
  <hr />
  <div class="document-signers-sort" #DocumentScrollingrequired="ngbTooltip" [ngbTooltip]="tipScrollingrequired" container="body" placement="right">
    <label class="document-signers-sort-label" (hover)="DocumentScrollingrequired.toggle()">
      <app-switch [(ngModel)]="document.scrolling_required" [disableInput]="!currentUser?.subscription.has_premium_features"></app-switch>
      <span class="document-signers-sort-text pro beta" [attr.data-pro-text]="translateService.instant('shared.pro')">{{ 'documents.requireViewWholeDocument' | translate }}</span>
    </label>
  </div>
  <hr />
  <div
    class="document-signers-sort"
    #DocumentAuditsPageTooltip="ngbTooltip"
    [ngbTooltip]="document.qualified ? (document.new_signature_style ? tipDocumentAuditsPage : tipQualifiedAuditPage) : tipDocumentAuditsPage"
    container="body"
    placement="right"
    *ngIf="(!document.qualified && document.new_signature_style) || (document.qualified && isNewDocument)"
  >
    <label class="document-signers-sort-label" (hover)="DocumentAuditsPageTooltip.toggle()">
      <app-switch [(ngModel)]="showAuditPage" [disableInput]="!showNewSignatureStyle && isQualified" (change)="switchAuditPage()"></app-switch>
      <span class="document-signers-sort-text beta" [ngClass]="{ disabled: !showNewSignatureStyle && isQualified }">{{ 'documents.includeAuditPageAtTheEnd' | translate }}</span>
    </label>
  </div>
</div>

<ng-container *ngIf="document?.locale">
  <div class="fields-separator" (click)="collapsedLanguage.toggle()" [attr.aria-expanded]="!isCollapsedLanguage">
    {{ 'documents.location' | translate }} <i class="fal fa-chevron-{{ isCollapsedLanguage ? 'down' : 'up' }}"></i>
  </div>
  <div class="sidebar-options-more fields-wrap" #collapsedLanguage="ngbCollapse" [(ngbCollapse)]="isCollapsedLanguage">
    <div class="d-flex">
      <label class="field__holder" [ngClass]="{ error: validationErrors?.document?.locale?.timezone }">
        <app-select
          style="margin-right: 5px"
          placeholder="{{ 'profile.timezone' | translate }}"
          [(ngModel)]="document.locale.timezone"
          [options]="timezones"
          [inputClass]="validationErrors?.document?.locale?.timezone ? 'error' : null"
          [required]="true"
        >
        </app-select>
        <p [hidden]="!validationErrors?.document?.locale?.timezone" class="field__error-message">{{ validationErrors?.document?.locale?.timezone }}</p>
      </label>
      <label class="field__holder">
        <app-select style="margin-left: 5px" placeholder="{{ 'profile.dateFormat' | translate }}" [(ngModel)]="document.locale.date_format" [disableSearch]="true" [options]="dateFormat"> </app-select>
      </label>
    </div>
  </div>
</ng-container>

<ng-template #tipScrollingrequired>
  <div class="tooltip__title">{{ 'documents.requireViewWholeDocument' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.requireScrollToEnd' | translate }}</div>
</ng-template>
<ng-template #tipDocumentMessage>
  <div class="tooltip__title">{{ 'documents.messageToSignatories' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.messageToSignatoriesDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentCC>
  <div class="tooltip__title">{{ 'documents.ccs' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.ccsDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentEletronicMarker>
  <div class="tooltip__title">{{ 'documents.authenticationMarks' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.authenticationMarksDescription' | translate }}</div>
</ng-template>
<ng-template #tipQualifiedEletronicMarker>
  <div class="tooltip__title">{{ 'documents.authenticationMarks' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.authenticationMarksDescriptionQualified' | translate }}</div>
</ng-template>
<ng-template #tipDocumentForceSignatureAppearance>
  <div class="tooltip__title">{{ 'documents.forceSignaturesAppearance' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.forceSignaturesAppearanceDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentRequireCPF>
  <div class="tooltip__title">{{ 'documents.doNotRequireCPF' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.doNotRequireCPFDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentRefuse>
  <div class="tooltip__title">{{ 'documents.documentRejection' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.documentRejectionDescription' | translate }}</div>
  <div *ngIf="showReminder" class="tooltip__descr messageAlert">{{ 'documents.cannotDisableIfSignatureReminderEnabled' | translate }}</div>
</ng-template>
<ng-template #tipDocumentAlertSignature>
  <div class="tooltip__title">{{ 'documents.signatureReminder' | translate }}</div>
  <div class="tooltip__descr" [innerHTML]="'documents.signatureReminderDescription' | translate"></div>
</ng-template>
<ng-template #tipDocumentAlertOverdue>
  <div class="tooltip__title">{{ 'documents.expirationReminder' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.expirationReminderDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentNotificationFinished>
  <div class="tooltip__title">{{ 'documents.completionNotification' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.completionNotificationDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentNotificationSigned>
  <div class="tooltip__title">{{ 'documents.signatureNotification' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.signatureNotificationDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentDeadline>
  <div class="tooltip__title">{{ 'documents.lockDocumentAfterDeadline' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.lockDocumentAfterDeadlineDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentAuditsPage>
  <div class="tooltip__title">{{ 'documents.includeAuditPage' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.includeAuditPageDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentNewSignatureStyle>
  <div class="tooltip__title">{{ 'documents.signatoryField' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.signatureField' | translate }}</div>
</ng-template>
<ng-template #tipDocumentOldStyle>
  <div class="tooltip__title">{{ 'documents.signatureFieldOnlySignature' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.signatureFieldOnlySignatureDescription' | translate }}</div>
</ng-template>
<ng-template #tipDocumentReplyTo>
  <div class="tooltip__title">{{ 'documents.emailToReply' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.emailToReplyDescription' | translate }}</div>
</ng-template>
<!-- Tooltip pra switch da página de auditoria em assinatura qualificada, remover quando o padrão antigo não existir mais -->
<ng-template #tipQualifiedAuditPage>
  <div class="tooltip__title">{{ 'documents.auditPage' | translate }}</div>
  <div class="tooltip__descr">{{ 'documents.auditPageDescription' | translate }}</div>
</ng-template>
<!-- . -->
